















































































































































import Encoding from "encoding-japanese";
import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  OnlineCapData,
  OnlineCapDisplayData
} from "@/views/patient/onlinecap/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import ResultDialog from "@/components/patient/onlinecap/ResultDialog.vue";

@Component({
  components: {
    OfficeSelect,
    ResultDialog
  }
})
export default class OnlineCapabilities extends Mixins(AxiosMixin, UtilMixin) {
  /** 選択中のアイテムリスト */
  @Prop({ type: Array, default: () => [] })
  private value!: OnlineCapDisplayData[];

  /** アイテムリスト */
  @Prop({ type: Array, default: () => [] })
  private items!: OnlineCapDisplayData[];

  /** ページ番号 */
  @Prop({ type: Number, default: 1 }) private reportsPageNo!: number;

  /** キーワード */
  @Prop({ type: String, default: "" }) private keyword!: string;

  /** 取込済みフラグ */
  @Prop({ type: Number, default: 0 }) private imported!: number;

  /** 選択事業所番号 */
  @Prop({ type: Number, default: 0 }) private selectOfficeId!: number;

  /** 1ページ当たりの表示件数 */
  @Prop({ type: Number, default: 25 }) private itemsPerPage!: number;

  /** 選択中のアイテムリストの状態変更 */
  @Emit() private input(value: OnlineCapDisplayData[]) {
    return value;
  }

  /** アイテムリストの状態変更 */
  @Emit("update:items") private updateItems(value: OnlineCapDisplayData[]) {
    return value;
  }

  /** ページ番号の状態変更 */
  @Emit("update:reportsPageNo") private updateReportsPageNo(value: number) {
    return value;
  }

  /** キーワードの状態変更 */
  @Emit("update:keyword") private updateKeyword(value: string) {
    return value;
  }

  /** 取込済みフラグの変更 */
  @Emit("update:imported") private updateImported(value: number) {
    return value;
  }

  /** リスト更新 */
  @Emit() private fetch() {
    return;
  }

  /** 取り込み結果ダイアログ */
  private dialog = false;

  /** 取り込み結果 */
  private impResult = "";
  /** 取り込み日時 */
  private impDate = "";

  /** 選択XMLファイル */
  private xmlfiles: File[] = [];
  /** XMLパースデータ */
  private xmlDatas: OnlineCapData[] = [];
  /** XML処理中の番号 */
  private curFileIdx = 0;

  /** 対象日 */
  private targetDate = this.dateToStr(new Date(), "yyyy-MM-dd");
  /** 対象日ダイアログ1 */
  private dialogDate1 = false;
  /** 対象日ダイアログ2 */
  private dialogDate2 = false;
  /** 対象日ダイアログ3 */
  private dialogDate3 = false;

  /** キーワード */
  private get Keyword() {
    return this.keyword;
  }
  private set Keyword(value: string) {
    this.updateKeyword(value);
  }

  /** 取込済みフラグ */
  private get Imported() {
    return this.imported;
  }

  /** 保険情報作成処理 */
  private async clickRegist() {
    this.postJsonCheck(
      window.base_url + "/api/patient/onlinecap/regist",
      {
        office_id: this.selectOfficeId,
        imported: 1,
        online_cap_item_key: this.value.map(objectId => objectId.item_key)
      },
      res => {
        this.impResult = res.data.message;
        this.impDate = res.data.import_date;
        this.dialog = true;
        this.updateImported(1);
        this.updateItems(res.data.online_caps);
        this.input([]);
      }
    );
  }

  /** 削除処理 */
  private async clickDel() {
    const ret = await this.$openConfirm(
      "選択された資格情報を削除します。\n資格情報を再度表示すると、再取り込みすることができます。\nよろしいですか？"
    );
    if (!ret) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/onlinecap/del",
      {
        office_id: this.selectOfficeId,
        imported: this.Imported,
        online_cap_ids: this.value.map(objectId => objectId.id)
      },
      res => {
        this.updateItems(res.data.online_caps);
        this.input([]);
      }
    );
  }

  private async outputXml1() {
    this.outputXml("/api/patient/onlinecap/output-xml", "OQSmuquc01req", true);
  }

  private async outputXml2() {
    this.outputXml(
      "/api/patient/onlinecap/output-xml-visit",
      "OQSmuhvq03req",
      true
    );
  }

  private async outputXml3() {
    this.outputXml(
      "/api/patient/onlinecap/output-xml-visit",
      "OQSmuhvq03req",
      false
    );
  }

  /** XML作成処理 */
  private async outputXml(
    url: string,
    fileNamePrefix: string,
    is_web_application: boolean
  ) {
    const date = this.targetDate.replaceAll("-", "");
    this.postJsonCheck(
      window.base_url + url,
      {
        office_id: this.selectOfficeId,
        imported: this.imported,
        online_cap_item_key: this.value.map(objectId => objectId.item_key),
        target_date: date,
        is_web_application: is_web_application
      },
      res => {
        const blob = new Blob([res.data]);
        const a = document.createElement("a");
        const dt = this.dateToStr(new Date(), "yyyyMMddHHmm");
        a.download = `${fileNamePrefix}_${dt}.xml`;
        a.href = URL.createObjectURL(blob);
        a.click();
      }
    );
  }

  /** XML取り込み */
  private async clickImport() {
    if (this.xmlfiles.length == 0) {
      //ファイル未選択
      return;
    }
    this.curFileIdx = 0;
    this.xmlDatas = [];
    const reader = new FileReader();

    reader.onload = () => {
      const codes = new Uint8Array(reader.result as ArrayBuffer);
      // const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: "UNICODE",
        from: "AUTO",
        type: "string"
      });

      this.createXMLParseData(
        new DOMParser().parseFromString(unicodeString, "text/xml")
      );
      this.curFileIdx++;
      if (this.xmlfiles.length > this.curFileIdx) {
        reader.readAsArrayBuffer(this.xmlfiles[this.curFileIdx]);
      } else {
        this.curFileIdx = 0;
        this.xmlfiles = [];
        //API呼び出し(DB登録)
        this.postJsonCheck(
          window.base_url + "/api/patient/onlinecap/save",
          {
            office_id: this.selectOfficeId,
            imported: this.Imported,
            online_caps: this.xmlDatas
          },
          res => {
            this.updateItems(res.data.online_caps);
            this.$openAlert(res.data.message);
          }
        );
      }
    };
    reader.readAsArrayBuffer(this.xmlfiles[this.curFileIdx]);
  }

  /** パースされたDomからデータを抜き出し */
  private createXMLParseData(dom: Document) {

  // 最初に空文字で初期化
  let qualificationValidity = "";

  // 資格有効性
  const qualificationValidityElement = dom.getElementsByTagName("QualificationValidity")[0];
  if (qualificationValidityElement && qualificationValidityElement.textContent) {
    qualificationValidity = qualificationValidityElement.textContent;
  }

    // ResultOfQualificationConfirmationタグ内
    for (
      let i = 0;
      i < dom.getElementsByTagName("ResultOfQualificationConfirmation").length;
      i++
    ) {
      const elemet = dom
        .getElementsByTagName("ResultOfQualificationConfirmation")
        .item(i);

      let onlineData: OnlineCapData = {
        //=== 資格情報 ===
        // 資格有効性
        qualification_validity: qualificationValidity,

        //=== 保険情報 ===
        //保険者番号
        insurer_number: this.getInnerHtml(elemet, "InsurerNumber"),
        //被保険者 記号   *
        insured_card_symbol: "",
        //被保険者 番号
        insured_identification_number: this.getInnerHtml(
          elemet,
          "InsuredIdentificationNumber"
        ),
        //被保険者 枝番   *
        insured_branch_number: "",
        //本人・家族   *
        family_classification: "",
        //氏名
        name: this.getInnerHtml(elemet, "Name"),
        //カナ
        kana: "",
        //性別
        sex: this.getInnerHtml(elemet, "Sex1"),
        //生年月日
        birthdate: this.getInnerHtml(elemet, "Birthdate"),
        //資格取得年月日
        qualification_date: this.getInnerHtml(elemet, "QualificationDate"),
        //被保険者証交付年月日
        certificate_issu_date: this.getInnerHtml(
          elemet,
          "InsuredCertificateIssuanceDate"
        ),
        //被保険者証有効開始年月日
        insured_valid_date: this.getInnerHtml(elemet, "InsuredCardValidDate"),
        //被保険者証有効終了年月日
        insured_exp_date: "",
        //被保険者証一部負担金割合
        ratio: "",
        //保険者名称
        insurer_name: this.getInnerHtml(elemet, "InsurerName"),

        //=== 高齢者受給者証 ===
        //高齢受給者証一部負担金割合
        elderly_ratio: "",
        //高齢受給者証有効開始年月日
        elderly_valid_date: "",
        //高齢受給者証有効終了年月日
        elderly_exp_date: "",

        //=== 高額療養費 ===
        //限度額適用認定証区分
        limit_classification: "",
        //限度額適用認定証適用区分
        limit_classification_flag: "",
        //限度額適用認定証有効開始年月日
        limit_valid_date: "",
        //限度額適用認定証有効終了年月日
        lmit_exp_date: "",

        //=== 公費 ===
        //公費有効開始年月日
        public_valid_date: "",
        //公費有効終了年月日
        public_exp_date: ""
      };

      //カナ
      onlineData.kana = this.getInnerHtml(elemet, "NameKana");
      //被保険者 記号   *
      onlineData.insured_card_symbol = this.getInnerHtml(
        elemet,
        "InsuredCardSymbol"
      );
      //被保険者 番号   *
      onlineData.insured_identification_number = this.getInnerHtml(
        elemet,
        "InsuredIdentificationNumber"
      );
      //被保険者 枝番   *
      onlineData.insured_branch_number = this.getInnerHtml(
        elemet,
        "InsuredBranchNumber"
      );
      //本人・家族   *
      onlineData.family_classification = this.getInnerHtml(
        elemet,
        "PersonalFamilyClassification"
      );
      //被保険者証有効終了年月日    *
      onlineData.insured_exp_date = this.getInnerHtml(
        elemet,
        "InsuredCardExpirationDate"
      );
      //被保険者証一部負担金割合    *
      onlineData.ratio = this.getInnerHtml(
        elemet,
        "InsuredPartialContributionRatio"
      );

      //高齢者受給者証
      onlineData = this.setElder(elemet, onlineData);

      //高額療養費
      onlineData = this.setLimit(elemet, onlineData);

      //公費
      onlineData = this.setPublic(elemet, onlineData);

      this.xmlDatas.push(onlineData);
    }
  }

  /** 高齢受給者証の設定 */
  private setElder(
    elemet: Element | null,
    onlineData: OnlineCapData
  ): OnlineCapData {
    //高齢者受給者証
    const elders = elemet?.getElementsByTagName(
      "ElderlyRecipientCertificateInfo"
    );
    if (elders && elders.length !== 0) {
      const elder = elders.item(0);
      //高齢受給者証一部負担金割合    *
      onlineData.elderly_ratio = this.getInnerHtml(
        elder,
        "ElderlyRecipientContributionRatio"
      );
      //高齢受給者証有効開始年月日    *
      onlineData.elderly_valid_date = this.getInnerHtml(
        elder,
        "ElderlyRecipientValidStartDate"
      );
      //高齢受給者証有効終了年月日    *
      onlineData.elderly_exp_date = this.getInnerHtml(
        elder,
        "ElderlyRecipientValidEndDate"
      );
    }
    return onlineData;
  }

  /** 高額療養費の設定 */
  private setLimit(
    elemet: Element | null,
    onlineData: OnlineCapData
  ): OnlineCapData {
    const limits = elemet?.getElementsByTagName(
      "LimitApplicationCertificateRelatedInfo"
    );
    if (limits && limits.length !== 0) {
      const limit = limits.item(0);

      //限度額適用認定証区分    *
      onlineData.limit_classification = this.getInnerHtml(
        limit,
        "LimitApplicationCertificateClassification"
      );
      //限度額適用認定証適用区分    *
      onlineData.limit_classification_flag = this.getInnerHtml(
        limit,
        "LimitApplicationCertificateClassificationFlag"
      );
      //限度額適用認定証有効開始年月日    *
      onlineData.limit_valid_date = this.getInnerHtml(
        limit,
        "LimitApplicationCertificateValidStartDate"
      );
      //限度額適用認定証有効終了年月日    *
      onlineData.lmit_exp_date = this.getInnerHtml(
        limit,
        "LimitApplicationCertificateValidEndDate"
      );
    }
    return onlineData;
  }

  /** 公費（医療扶助）の設定 */
  private setPublic(
    element: Element | null,
    onlineData: OnlineCapData
  ): OnlineCapData {
    const publics = element?.getElementsByTagName("PublicExpenseResultList");
    if (publics && publics.length !== 0) {
      //開始日
      onlineData.public_valid_date = this.getInnerHtml(
        element,
        "QualificationDate"
      );
      //終了日
      onlineData.public_exp_date = this.getInnerHtml(
        element,
        "DisqualificationDate"
      );
    }
    return onlineData;
  }

  /** タグの中身取得 */
  private getInnerHtml(element: Element | null, tagname: string): string {
    if (element?.getElementsByTagName(tagname).length !== 0) {
      return element?.getElementsByTagName(tagname).item(0)?.innerHTML ?? "";
    }
    return "";
  }
}

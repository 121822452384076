









































































































import { Component, Ref } from "vue-property-decorator";
import { Patient } from "#/model/patient";
import Base from "@/components/patient/info/Base";
import { ExPatient, DefaultExPatient } from "@/views/patient/info/types";
import PatientInfoItem from "@/components/patient/info/PatientInfoItem.vue";
import { Choice } from "@/types";

@Component({
  components: {
    PatientInfoItem
  }
})
export default class PatientHistoryEdit extends Base {
  /** Ref */

  @Ref("form") private readonly form!: PatientInfoItem;

  /** 変数 */

  /** 右列に表示する編集対象の利用者情報 */
  private targetPatient: ExPatient = DefaultExPatient();

  /** 左列に表示する比較用の利用者情報 */
  private compPatients: ExPatient[] = [];

  /** 利用者履歴ID */
  private historyId = 0;

  /** 予約された履歴か */
  private isReservedHistory = false;

  /** 保存前引き落とし区分 */
  private beforeSavetWithdrawalStKbn = 0;

  /** グループマスタ */
  private groupItems: Choice[] = [];

  /** AI使用可能フラグ */
  private is_ai_route_use = false;

  /** 変更後の緯度経度 */
  private latlng = { lat: 0, lng: 0, isUpdate: false };

  private isLoaded = false;

  /** 関数 */

  private created() {
    if (this.$route.params.historyId) {
      this.historyId = Number(this.$route.params.historyId);
    }
    this.fetchPatient();
  }

  mounted() {
    /** ヘッダーセット */
    this.setDefaultHeader();
  }

  /** 利用者情報取得 */
  private fetchPatient() {
    // 編集の場合
    this.postJsonCheck(
      window.base_url + "/api/patient/history/get",
      {
        patient_id: Number(this.$route.params.id),
        patient_history_id: this.historyId
      },
      res => {
        this.targetPatient = res.data.target_patient as ExPatient;
        this.compPatients = res.data.comp_patients;

        this.patient = { ...this.targetPatient.patient };
        this.office = this.targetPatient.office;

        this.isReservedHistory = this.targetPatient.is_reserved_history;
        this.beforeSavetWithdrawalStKbn = this.patient.info.withdrawal_st_kbn;
        this.preNursingMonths = this.makeCheckboxForPreNursingMonth(
          this.patient.info.prevent_gensan_month,
          this.patient.info.patient_pre_nursing_months
        );

        this.is_ai_route_use = res.data.is_ai_route_use;
        this.latlng = {
          lat: Number(this.patient.info.latitude),
          lng: Number(this.patient.info.longitude),
          isUpdate: false
        };

        this.setLoaded();
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      }
    );

    // グループリスト取得
    this.postJsonCheck(
      window.base_url + "/api/patient/group/get",
      { patient_id: Number(this.$route.params.id) },
      res => {
        this.groupItems = res.data.groups;
      }
    );
  }

  /** 履歴保存 */
  private async save() {
    this.patient.info.id = this.historyId;
    this.patient.info.patient_id = Number(this.$route.params.id);

    // バリデーション
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    // 引落開始設定アラート
    if (
      this.patient.info.withdrawal_st_kbn == 1 &&
      this.beforeSavetWithdrawalStKbn === 2
    ) {
      if (
        !(await this.$openConfirm(
          "引落開始設定が「開始する」になっていますが、このまま登録しますか？"
        ))
      ) {
        return;
      }
    }

    // グループ情報整形
    this.setGroupParameter();

    // 施設コードのリセット
    this.resetFacilityCode();

    // 減算起算開始月の設定
    this.patient.info.patient_pre_nursing_months = this.preNursingMonths
      .filter(v => v.is_checked == 1)
      .map(v => {
        return {
          id: v.id,
          patient_id: 0,
          exec_yearmonth: v.exec_yearmonth,
          is_auto: v.is_auto,
          created_at: v.created_at ?? "",
          updated_at: v.updated_at ?? "",
          deleted_at: v.deleted_at ?? ""
        };
      });

    if (this.latlng.isUpdate) {
      this.patient.info.latitude = String(this.latlng.lat);
      this.patient.info.longitude = String(this.latlng.lng);
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/history/save",
      { patient: this.patient, is_update_latlng: this.latlng.isUpdate ? 1 : 0 },
      async res => {
        if (res.data.errors && res.data.errors.length > 0) {
          await this.$openAlert(res.data.errors.join("\n"));
          return;
        }
        this.setNoEditMode();
        this.$router.push({
          name: "PatientHistoryList",
          params: { id: this.$route.params.id }
        });
      }
    );
  }

  /** 履歴削除 */
  private async deletePatientHistory() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    this.patient.info.id = this.historyId;
    this.patient.info.patient_id = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/patient/history/delete",
      {
        patient: this.patient
      },
      () => {
        this.setNoEditMode();
        this.$router.push({
          name: "PatientHistoryList",
          params: { id: this.$route.params.id }
        });
      }
    );
  }

  /** 履歴上部に表示するタイトル取得 */
  private getHistorySubTitle(patient: Patient, isEditTarget = false): string {
    const dateStr = `${patient.info.start_date} 〜 ${patient.info.end_date}`;
    const reserveStr = isEditTarget && this.isReservedHistory ? "（予約）" : "";

    return dateStr + reserveStr;
  }
}
